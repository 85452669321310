import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a4eb8762"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "setting"
};
const _hoisted_2 = {
  class: "setting-pc"
};
const _hoisted_3 = {
  class: "top-decorate"
};
const _hoisted_4 = {
  class: "top-decorate-4"
};
const _hoisted_5 = {
  class: "top-decorate-3"
};
const _hoisted_6 = {
  class: "container"
};
const _hoisted_7 = {
  class: "bottom-copyright"
};
import { MyHeader } from '@/components/setting-header';
import DecorateLine from '@/components/decorate-line';
import BottomCopyright from '@/components/bottom-copyright';
import NavMenu from '@/components/nav-menu';
export default {
  __name: 'setting',

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_aside = _resolveComponent("el-aside");

      const _component_router_view = _resolveComponent("router-view");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(MyHeader)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_unref(DecorateLine), {
        multiple: 5
      })]), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(DecorateLine), {
        multiple: 4
      })]), _createElementVNode("div", _hoisted_5, [_createVNode(_unref(DecorateLine), {
        multiple: 3
      })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_aside, null, {
        default: _withCtx(() => [_createVNode(_unref(NavMenu))]),
        _: 1
      }), _createVNode(_component_el_main, null, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      })]), _createElementVNode("div", _hoisted_7, [_createVNode(_unref(BottomCopyright))])])]);
    };
  }

};