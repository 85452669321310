import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-88d65b78"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "menu-mobile"
};
import { ref } from 'vue';
import { useRoute } from 'vue-router';
export default {
  __name: 'menu-mobile',

  setup(__props, {
    expose: __expose
  }) {
    const route = useRoute();
    const drawerVisible = ref(false);

    const handleClick = () => {
      drawerVisible.value = false;
    };

    __expose({
      drawerVisible
    });

    return (_ctx, _cache) => {
      const _component_User = _resolveComponent("User");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_menu_item = _resolveComponent("el-menu-item");

      const _component_Message = _resolveComponent("Message");

      const _component_Key = _resolveComponent("Key");

      const _component_el_menu = _resolveComponent("el-menu");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_drawer, {
        modelValue: drawerVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => drawerVisible.value = $event),
        "with-header": false,
        size: "200px",
        direction: "ltr"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          "background-color": "#fff",
          "text-color": "#000",
          "active-text-color": "#fff",
          router: true,
          "default-active": _unref(route).path,
          onClick: handleClick
        }, {
          default: _withCtx(() => [_createVNode(_component_el_menu_item, {
            index: "/setting/profile"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_User)]),
              _: 1
            }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('settingMain.menu_list.edit_profile')), 1)]),
            _: 1
          }), _createVNode(_component_el_menu_item, {
            index: "/setting/email"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_Message)]),
              _: 1
            }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('settingMain.menu_list.bind_email')), 1)]),
            _: 1
          }), _createVNode(_component_el_menu_item, {
            index: "/setting/password"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_Key)]),
              _: 1
            }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('settingMain.menu_list.edit_password')), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["default-active"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};